@import "src/assets/styles/main";

.verify-container {
  margin-top: $base-spacing-unit * 4;
  margin-bottom: $base-spacing-unit * 8;
  text-align: center;

  .form-container {
    margin-left: auto;
    margin-right: auto;
  }
}
