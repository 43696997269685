@import "src/assets/styles/main";

.header {
  margin-top: $base-spacing-unit;

  .nav-link {
    color: $button-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      background-color: $secondary-color;
      color: $main-color;
    }
  }

  .active {
    background-color: $secondary-color;
  }
}