@import "src/assets/styles/main";

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;
  width: 100%;

  @media #{$small} {
    max-width: 540px;
  }

  @media #{$medium} {
    max-width: 720px;
  }

  @media #{$large} {
    max-width: 960px;
  }

  @media #{$extra-large} {
    max-width: 1280px;
  }
}
