@import "src/assets/styles/main";

.login-form {
  color: $white;
  position: fixed;
  top: 35%;
  left: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  border: 2px solid $border-color;
  border-radius: $border-radius-base;
  background-color: $main-color;
  margin-left: auto;
  margin-right: auto;
  padding: $base-spacing-unit * 4;

  &__button {
    height: 50px;
    width: 180px;
    text-align: center;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: bold;
    color: $main-color;
    background-color: $button-color;
    margin-top: $base-spacing-unit;
    font-size: $font-size-h6;
    border-radius: 6px;
  }
}