.swal-title {
  font-size: 22px;
  padding: $half-spacing-unit $base-spacing-unit;
}

.swal-footer {
  margin-bottom: $half-spacing-unit;
  padding: $half-spacing-unit $base-spacing-unit;
}

.button {
  align-items: center !important;
  border: 1px solid transparent !important;
  display: inline-flex !important;
  font-size: $btn-font-size !important;
  font-weight: $btn-font-weight !important;
  height: 42px !important;
  justify-content: center !important;
  min-width: 150px !important;
  padding: 0 $base-spacing-unit !important;
  position: relative !important;
  text-align: center !important;
  text-transform: uppercase !important;
  transition: 0.3s !important;
  user-select: none !important;
  white-space: nowrap !important;

  @media #{$large} {
    font-size: 16px !important;
    min-width: 190px !important;
    padding: 0 $base-spacing-unit * 3 !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.is-disabled {
    opacity: 0.6 !important;
    pointer-events: none !important;
  }

  &--primary {
    background-color: $button-color !important;
    color: $main-color !important;

    &:not([disabled]):hover {
      background-color: transparent !important;
      color: $main-color !important;
    }
  }
}

.swal2-confirm {
  @extend .button;
  @extend .button--primary;
}