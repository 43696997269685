@import "src/assets/styles/main";

.table-wrapper {
  margin-top: $base-spacing-unit * 2;

  h3 {
    font-family: $open-sans;
    font-weight: bold;
    text-align: center;
  }

  &__add-new {
    text-align: right;
    padding-right: $base-spacing-unit * 2;
  }
}
