@import "src/assets/styles/main";

.history {
  margin-top: $base-spacing-unit * 2;

  .item-wrapper {
    padding: $base-spacing-unit;
    border: 1px solid black;
    display: inline-block;
    margin-right: $half-spacing-unit;
    margin-top: $half-spacing-unit;
  }
}
