h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $headings-color;
  display: block;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-top: 0;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-bottom: $base-spacing-unit;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-bottom: $half-spacing-unit;
}

h1,
.h1 {
  font-size: $font-size-h2;
  @media #{$large} {
    font-size: $font-size-h1;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
}

h4,
.h4 {
  font-size: $font-size-h4;
}

h5,
.h5 {
  font-size: $font-size-h5;
}

h6,
.h6 {
  font-size: $font-size-h6;
}

p {
  margin: 0 0 $base-spacing-unit;
}