@import "src/assets/styles/main";

.text-input {
  position: relative;

  &__label {
    display: block;
    margin-bottom: $half-spacing-unit;
    font-family: $font-family-base;
  }

  &__control {
    background-color: transparent;
    border: 1px solid $border-color;
    color: $white;
    display: block;
    padding: 9px $base-spacing-unit;
    width: 100%;

    &.is-invalid {
      box-shadow: none;
      border-color: $error-color;
    }
  }

  &__error {
    color: $error-color;
    position: absolute;
  }

  &__error-special {
    font-size: 12px;
  }

  &__white-bg {
    color: $black;
  }
}