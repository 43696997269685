* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: $font-size-base;
}

body {
  background-size: 100%;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $color-text-body;
  background-color: $body-bg;
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  overscroll-behavior-y: none;
}

input,
button,
select,
textarea {
  background: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  transition: 0.3s;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

u {
  text-decoration: none;
}

.push--top {
  margin-top: $base-spacing-unit * 2;
}

.text--center {
  text-align: center;
}

.row--padding {
  padding-left: $base-spacing-unit * 2;
  padding-right: $base-spacing-unit * 2;
}

.push--half--top {
  margin-top: $base-spacing-unit;
}